// extracted by mini-css-extract-plugin
export var author = "SpeakersSection-module--author--fd252";
export var description = "SpeakersSection-module--description--7e7f2";
export var heading = "SpeakersSection-module--heading--82769";
export var image = "SpeakersSection-module--image--c0566";
export var opacityEnter = "SpeakersSection-module--opacityEnter--78a2e";
export var opacityExit = "SpeakersSection-module--opacityExit--2bd19";
export var paragraph = "SpeakersSection-module--paragraph--996be";
export var personInfoWrapper = "SpeakersSection-module--personInfoWrapper--7eaac";
export var rollDown = "SpeakersSection-module--rollDown--fac68";
export var rollUp = "SpeakersSection-module--rollUp--92608";
export var row = "SpeakersSection-module--row--37a07";
export var slideInDown = "SpeakersSection-module--slideInDown--0af50";
export var slideOutUp = "SpeakersSection-module--slideOutUp--0ae5d";
export var splashEnter = "SpeakersSection-module--splashEnter--d5705";
export var splashExit = "SpeakersSection-module--splashExit--68e56";
export var title = "SpeakersSection-module--title--5e4be";