import React from 'react';
import { capitalizeWords } from '@/base/helpers/strings';
import BoxWithTitle from '@/components/elements/BoxWithTitle';
import SectionRichText from '@/components/sections/SectionRichText';
import Image from '@/components/elements/Image';
import type { SpeakerSectionTypes } from './SpeakersSection.types';
import * as styles from './SpeakersSection.module.scss';

export default function SpeakersSection({ title, speakers }: SpeakerSectionTypes) {
  if (!speakers) return null;
  return (
    <BoxWithTitle title={title}>
      {speakers.map(({ content: { bio, image, name, title }, uuid }) => (
        <div key={uuid} className={styles.row}>
          <div className={styles.author}>
            <Image data={image} fluid className={styles.image} />
            <div className={styles.personInfoWrapper}>
              <h3 className={styles.heading}>{capitalizeWords(name)}</h3>
              <p className={styles.title}>{title}</p>
            </div>
          </div>
          <SectionRichText body={bio} className={styles.description} />
        </div>
      ))}
    </BoxWithTitle>
  );
}
